import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Results from "../slices/calculator/results"
import { renderText } from "../utils"
import Preloader from "../design-system/preloader"
import styled from "@emotion/styled"
import Button from "../design-system/button"
import { Flex, Text, Box } from "@chakra-ui/core"

import Amplify, { API, graphqlOperation, navItem } from "aws-amplify"
import awsConfig from "../aws-exports"
import { getResults } from "../graphql/queries"

import Layout from "../layouts/layout"
import { parseMetadataFromPrismic } from "../layouts/seo"

Amplify.configure(awsConfig)

const InputValueColumn = styled.div`
  color: #484f58;
  font-weight: 300;

  .text-ellipsis {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .trips-label {
  }

  .class-label {
  }

  .mobile-only {
    display: none;
  }

  @media (max-width: 987px) {
    .airport-name,
    .not-essential {
      display: none;
    }
    .mobile-only {
      display: block;
    }

    .text-ellipsis {
      width: unset;
    }
  }

  span {
    font-weight: 500;
  }

  span.airport-name {
    font-weight: 300;
  }
`

const CalculatorFormWrapper = styled.div`
  .uk-button-default {
    word-wrap: break-word;
    background-color: #0966bf !important; /*#ff6a6a */
    border: none !important;

    &:hover {
      border: none !important;
    }

    &::before,
    &::after {
      background-color: #0d5396; /*#ff8080;*/
    }
  }

  h3 {
    font-size: 30px;
    font-family: "Raleway";

    font-weight: 300;

    b {
      border-bottom: 2px solid #67d875 !important;
      background: none;
      font-weight: 300;
    }

    @media (max-width: 987px) {
      font-size: 24px;
    }
  }
`

const ShowInputValues = ({ inputValues }) => {
  const hasClassSet =
    inputValues.filter(({ travelClass }) => travelClass).length > 0
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "0 0 10px 0",
        fontSize: "14px",
      }}
    >
      <div style={{ display: "flex", padding: "0px 10px" }}>
        <InputValueColumn>
          {inputValues.map(({ originLocation }) => (
            <div
              className="text-ellipsis"
              style={{ textAlign: "left", height: "22px" }}
            >
              <span>{originLocation.iata}</span>
              <span className="airport-name">{` - ${
                originLocation.iata == "LON"
                  ? "Any London"
                  : originLocation.name.replace(/airport/i, "").trim()
              }`}</span>
            </div>
          ))}
        </InputValueColumn>
        <InputValueColumn>
          {inputValues.map(({ _ }) => (
            <Box
              style={{
                padding: "0px 10px",
                height: "22px",
              }}
            >
              <svg
                style={{
                  height: "22px",
                  transform: "rotate(90deg)",
                  marginTop: "-1px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#858e97"
                  d="M448 336v-40L288 192V79.2c0-17.7-14.8-31.2-32-31.2s-32 13.5-32 31.2V192L64 296v40l160-48v113.6l-48 31.2V464l80-16 80 16v-31.2l-48-31.2V288l160 48z"
                />
              </svg>{" "}
            </Box>
          ))}
        </InputValueColumn>
        <InputValueColumn>
          {inputValues.map(({ destinationLocation }) => (
            <div
              className="text-ellipsis"
              style={{ textAlign: "left", height: "22px" }}
            >
              <span>{destinationLocation.iata}</span>
              <span className="airport-name">{` - ${
                destinationLocation.iata == "LON"
                  ? "Any London"
                  : destinationLocation.name.replace(/airport/i, "").trim()
              }`}</span>
            </div>
          ))}
        </InputValueColumn>
      </div>
      <InputValueColumn>
        {inputValues.map(({ trips, frequency }) => (
          <div
            style={{ padding: "0 10px", height: "22px" }}
            className="trips-label"
          >
            <span>{trips}</span>
            {` round trip${trips != 1 ? "s" : ""} `}
            <span>{`per ${frequency}`}</span>
          </div>
        ))}
      </InputValueColumn>
      <InputValueColumn>
        {hasClassSet &&
          inputValues.map(({ travelClass }) => (
            <div
              className="not-essential class-label"
              style={{ padding: "0 10px", height: "22px" }}
            >
              {travelClass ? (
                <span style={{ textTransform: "capitalize" }}>
                  {travelClass}
                </span>
              ) : (
                <span style={{ color: "white" }}>.</span>
              )}
            </div>
          ))}
      </InputValueColumn>
      <InputValueColumn>
        {inputValues.map(({ nights }) => (
          <div
            className="not-essential nights-label"
            style={{ padding: "0 10px", height: "22px" }}
          >
            {nights ? (
              <>
                <span>{nights}</span>
                <>{" hotel nights per trip"}</>
              </>
            ) : (
              <span style={{ color: "white" }}>.</span>
            )}
          </div>
        ))}
      </InputValueColumn>
    </div>
  )
}

const CalculatorLayout = ({ node, nav, siteMetadata, children }) => (
  <Layout
    nav={nav}
    siteTitle={"Calculator Results"}
    host={siteMetadata.host}
    seoData={parseMetadataFromPrismic(node, "en-gb")}
  >
    {children}
  </Layout>
)

export default ({ data, location }) => {
  const [results, setResults] = useState(null)

  const meta = data.site.siteMetadata

  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  const nav = doc.node.header_navigation.map(
    ({
      header_navigation__label,
      header_navigation__section,
      header_navigation__type_button,
      header_navigation_link,
    }) => ({
      label: header_navigation__label,
      section: header_navigation__section,
      isButton: header_navigation__type_button,
      link: header_navigation_link,
    })
  )

  const calculatorCopy = doc.node.body.find(
    object => object.type == "calculator"
  )

  const {
    calculator_results_heading,
    calculator_results_heading_company,
    results_page_nav_link_text,
    results_page_button_link_text,
  } = calculatorCopy.primary

  const charts = calculatorCopy.fields.map(
    ({
      calculator_chart_title,
      calculator_chart_summary,
      calculator_chart_calculated_value_label,
      calculator_chart_comparison_value_label_one,
      calculator_chart_comparison_value_one,
      calculator_chart_comparison_value_label_two,
      calculator_chart_comparison_value_two,
      calculator_chart_id,
      calculator_chart_icon,
    }) => ({
      title: renderText(calculator_chart_title),
      summary: renderText(calculator_chart_summary),
      calculatedValueLabel:
        calculator_chart_calculated_value_label &&
        renderText(calculator_chart_calculated_value_label),
      comparisonValueLabelOne:
        calculator_chart_comparison_value_label_one &&
        renderText(calculator_chart_comparison_value_label_one),
      comparisonValueOne: calculator_chart_comparison_value_one,
      comparisonValueLabelTwo:
        calculator_chart_comparison_value_label_two &&
        renderText(calculator_chart_comparison_value_label_two),
      comparisonValueTwo: calculator_chart_comparison_value_two,
      id: calculator_chart_id,
      icon: calculator_chart_icon,
    })
  )

  const resultsHeading =
    calculator_results_heading && renderText(calculator_results_heading)

  const resultsHeadingCompany =
    calculator_results_heading_company &&
    renderText(calculator_results_heading_company)

  useEffect(() => {
    const fetchResults = async id => {
      try {
        const response = await API.graphql(graphqlOperation(getResults, { id }))
        setResults(JSON.parse(response.data.getResults))
      } catch (e) {
        console.log(e)
      }
    }

    if (location.search) {
      fetchResults(location.search.replace("?", ""))
    }
  }, [location])

  return !results ? (
    <Preloader />
  ) : (
    <CalculatorLayout
      meta={meta}
      nav={[
        ...(results_page_nav_link_text
          ? [
              {
                label: results_page_nav_link_text,
                section: "calculator",
                link: {
                  _linkType: "Link.document",
                  _meta: {
                    uid: null,
                  },
                },
                linkType: "button",
              },
            ]
          : []),
      ]}
      node={doc.node}
      siteMetadata={data.site.siteMetadata}
    >
      <div style={{ height: "60px" }} />
      <section id="calculator" style={{ background: "#ffffff" }}>
        <div>
          <CalculatorFormWrapper>
            <Results
              {...results}
              inputValues={
                <ShowInputValues inputValues={results.inputValues} />
              }
              heading={
                <h3
                  id="results-heading"
                  style={{
                    width: "fit-content",
                    textAlign: "center",
                    padding: "10px 0 20px 0",
                    alignSelf: "center",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      results.calculationType == "company"
                        ? resultsHeadingCompany.replace(
                            "[number]",
                            results.numberOfEmployees
                          )
                        : resultsHeading,
                  }}
                ></h3>
              }
              charts={charts}
            />
            {results_page_button_link_text && (
              <div style={{ textAlign: "center", margin: "20px 0 40px 0" }}>
                <Button as={Link} to="/#calculator">
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: renderText(results_page_button_link_text),
                    }}
                  />
                </Button>
              </div>
            )}
          </CalculatorFormWrapper>
        </div>
      </section>
    </CalculatorLayout>
  )
}

export const query = graphql`
  query CalculatorResultsQuery {
    prismic {
      allHomepages: allWebsite_homepages {
        edges {
          node {
            _meta {
              uid
              id
              type
              lang
            }
            meta_title
            meta_description
            display_name
            canonical_url
            card_description
            card_image
            card_imageSharp {
              childImageSharp {
                fixed(width: 1200, height: 630) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_image_square
            card_image_squareSharp {
              childImageSharp {
                fixed(width: 400, height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_title
            header_navigation {
              header_navigation__label
              header_navigation__section
              header_navigation__type_button
              header_navigation_link {
                _linkType
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__Document {
                  _meta {
                    uid
                  }
                }
              }
            }
            body {
              ... on PRISMIC_Website_homepageBodyCalculator {
                type
                label
                primary {
                  calculator_results_heading
                  calculator_results_heading_company
                  results_page_nav_link_text
                  results_page_button_link_text
                }
                fields {
                  calculator_chart_title
                  calculator_chart_summary
                  calculator_chart_calculated_value_label
                  calculator_chart_comparison_value_label_one
                  calculator_chart_comparison_value_one
                  calculator_chart_comparison_value_label_two
                  calculator_chart_comparison_value_two
                  calculator_chart_id
                  calculator_chart_icon
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        host
      }
    }
  }
`
